import React, {
	type FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Button,
	FormRow,
	ImageInput,
	Input,
	TextArea,
	Tooltip,
} from 'components/UI';
import { useAppDispatch, useAppSelector, useValidation } from 'shared/hooks';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { AccountsTab, type IAccountsDetails } from '../types';
import AccountService from '../service';
import { setSelectedMainData } from '../store/reducer';
import routes from 'shared/constants/routes';
import { type Nullable } from 'shared/types';
import { useAuth } from 'modules/auth';

interface IProps {
	isCreating?: boolean;
}
const BasicDataTab: FC<IProps> = ({ isCreating }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { refreshToken } = useAuth();
	const enums = useAppSelector(state => state.app.enums);
	const { has2fa } = useAppSelector(state => state.app);
	const { permissions, role } = useAppSelector(state => state.app.user);

	const { mainData: selectedAccount, id } = useAppSelector(
		state => state.accounts.selectedAccount,
	);
	const [logo, setLogo] = useState<Nullable<Blob>>(null);
	const [isEditing, setIsEditing] = useState<boolean>(Boolean(isCreating));
	const [logoExist, setLogoExist] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		trigger,
		watch,
		setValue,
		clearErrors,
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IAccountsDetails>({ mode: 'all' });
	const watcher = watch();

	const { stringValidation, urlValidation } = useValidation();

	const fillForm = useCallback(() => {
		if (!selectedAccount) return;
		Object.entries(selectedAccount).forEach(([key, value]) => {
			setValue(key as keyof IAccountsDetails, value, { shouldTouch: false });
		});
		clearErrors();
	}, [selectedAccount, clearErrors, setValue]);

	const toggleEditing = useCallback(() => {
		setIsEditing(prev => !prev);
	}, []);

	const handleLogoUpload = useCallback(
		(logo: Blob) => {
			setLogo(logo);
			setLogoExist(true);
			void trigger('logoUri');
		},
		[trigger],
	);

	useEffect(() => {
		if (!watcher.description) return;
		if (watcher.description.length > 50) {
			setValue('description', watcher.description.slice(0, 50));
		}
	}, [watcher.description]);

	const handleCancel = useCallback(() => {
		setLogo(null);
		fillForm();
		toggleEditing();
		clearErrors();
		if (isCreating) navigate(-1);
	}, [clearErrors, fillForm, isCreating, navigate, toggleEditing]);

	const handleConfirm = useCallback(
		async (data: IAccountsDetails) => {
			try {
				setIsLoading(true);
				if (isCreating) {
					const created = await AccountService.createAccountsData(data);
					dispatch(setSelectedMainData(created));
					if (logo) {
						const { logoUri } = await AccountService.updateAccountsLogo(
							created.id,
							logo,
						);
						setValue('logoUri', logoUri);
						setLogo(null);
					}

					await refreshToken();
					navigate(routes.accounts.details(created.id, AccountsTab.BASIC_DATA));
				} else {
					if (!id) return;
					const edited = await AccountService.setAccountDetails(id, data);
					dispatch(setSelectedMainData(edited));
					if (!logo) return;
					const { logoUri } = await AccountService.updateAccountsLogo(
						edited.id,
						logo,
					);
					setValue('logoUri', logoUri);
					setLogoExist(true);
					setLogo(null);
				}
			} catch (e) {
				fillForm();
			} finally {
				toggleEditing();
				setIsLoading(false);
			}
		},
		[
			dispatch,
			fillForm,
			id,
			isCreating,
			logo,
			navigate,
			setValue,
			toggleEditing,
		],
	);

	const status = useMemo<string>(() => {
		if (!enums || !selectedAccount) return '';
		return (
			Object.values(enums.ShopAccountStatus.values).find(
				({ id }) => id === selectedAccount.status,
			)?.value ?? ''
		);
	}, [enums, selectedAccount]);

	const isChanged = useMemo<boolean>(() => {
		if (logo ?? isCreating) return true;
		if (!selectedAccount) return false;
		return Object.keys(watcher)
			.map(k => {
				const key = k as keyof IAccountsDetails;
				if (key === 'logoUri') return false;
				if (!watcher[key] && !selectedAccount[key]) return false;
				return watcher[key] !== selectedAccount[key];
			})
			.some(changed => changed);
	}, [logo, selectedAccount, watcher, isCreating]);

	const canEditAccountPermission = useMemo<boolean>(() => {
		if (role === 'client_super_admin') return true;
		const permission = permissions.find(
			permission => permission.accountId === id,
		);
		return (
			permission?.permissionsNames?.includes('client_can_edit_account') ?? false
		);
	}, [id, permissions, role]);

	useEffect(fillForm, [fillForm, selectedAccount]);

	return (
		<div>
			<div className="flex justify-between items-center py-2">
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{t('modules.clientDetails.settings')}
				</h4>
				{!isEditing && canEditAccountPermission && (
					<Button
						disabled={!has2fa}
						data-test-id="client-account-edit"
						variant="secondary"
						onClick={toggleEditing}>
						<EditIcon className="mr-2.5" />
						{t('ui.buttons.edit')}
						{!has2fa && (
							<Tooltip
								title={t('ui.tooltip.2fa.title')}
								text={t('ui.tooltip.2fa.text')}
							/>
						)}
					</Button>
				)}
			</div>

			{!isEditing && logoExist && watcher.logoUri && (
				<FormRow label={t('modules.clientDetails.details.logo')}>
					<div className="max-w-[512px] w-full">
						<img
							onError={() => {
								setLogoExist(false);
							}}
							onLoad={() => {
								setLogoExist(true);
							}}
							src={`${watcher.logoUri}?cache=${Date.now()}`}
							alt=""
							className="max-w-[70px] w-full h-auto"
						/>
					</div>
				</FormRow>
			)}

			{isEditing && (
				<FormRow label={t('modules.clientDetails.details.logo')}>
					<div className="max-w-[512px] w-full">
						<ImageInput
							uploadedUrl={watcher.logoUri}
							onUpload={handleLogoUpload}
						/>
					</div>
				</FormRow>
			)}

			{!logoExist && !isEditing && (
				<FormRow label={t('modules.clientDetails.details.logo')}>
					<div className="max-w-[512px] w-full">
						<Button
							data-test-id="client-account-upload"
							disabled
							variant="secondary"
							className="cursor-pointer">
							{t('ui.input.upload')}
						</Button>
					</div>
				</FormRow>
			)}

			{!isCreating && (
				<div className="py-5 border-b border-b-gray-200">
					<div className="max-w-[900px] flex justify-between items-center">
						<h5 className="text-gray-700 text-sm font-medium">
							{t('modules.clientDetails.details.accountId')}
						</h5>
						<div className="max-w-[512px] w-full">
							<Input
								data-test-id="client-account-id"
								disabled
								value={selectedAccount?.businessId}
								isError={Boolean(errors.businessId)}
								errorText={errors.businessId?.message}
								wrapperClassName="max-w-[512px] w-full"
							/>
						</div>
					</div>
				</div>
			)}

			<FormRow
				label={`${t('modules.clientDetails.details.accountName')} ${
					isCreating ? '*' : ''
				}`}>
				<Input
					data-test-id="client-account-accountName"
					placeholder={t('modules.clientDetails.placeholders.accountName')}
					disabled={!isEditing}
					isError={Boolean(errors.title)}
					errorText={errors.title?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('title', stringValidation)}
				/>
			</FormRow>

			{!isCreating && (
				<FormRow label={t('modules.clientDetails.details.status')}>
					<Input
						data-test-id="client-account-status"
						disabled
						value={status}
						isError={Boolean(errors.status)}
						errorText={errors.status?.message}
						wrapperClassName="max-w-[512px] w-full"
					/>
				</FormRow>
			)}

			<FormRow label={t('modules.settings.fields.address')}>
				<Input
					data-test-id="client-account-address"
					placeholder={t('modules.settings.placeholders.address')}
					disabled={!isEditing}
					isError={Boolean(errors.address)}
					errorText={errors.address?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('address', {
						...stringValidation,
						required: false,
					})}
				/>
			</FormRow>

			<FormRow
				label={t('modules.clientDetails.details.description')}
				wrapperClassName="!items-start">
				<TextArea
					data-test-id="client-account-description"
					placeholder={t('modules.clientDetails.placeholders.description')}
					disabled={!isEditing}
					isError={Boolean(errors.description)}
					errorText={errors.description?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('description', { ...stringValidation, required: false })}
				/>
			</FormRow>

			<FormRow label={t('modules.clientDetails.details.site')}>
				<Input
					data-test-id="client-account-site"
					placeholder={t('modules.clientDetails.placeholders.site')}
					disabled={!isEditing}
					isError={Boolean(errors.site)}
					errorText={errors.site?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('site', { ...urlValidation, required: false })}
				/>
			</FormRow>

			{isEditing && (
				<div className="flex justify-between items-center mt-6">
					<Button
						data-test-id="client-account-cancel"
						variant="secondary"
						onClick={handleCancel}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button
						data-test-id="client-account-confirm"
						variant="primary"
						isLoading={isLoading}
						onClick={handleSubmit(handleConfirm)}
						disabled={!isValid || !isChanged}>
						{t('ui.buttons.confirm')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default BasicDataTab;
