import oidcClient from './oidcClient';
import { config } from 'shared/constants/config';
import {
	localStorageKeys,
	localStorageService,
} from 'shared/utils/localStorage';
import {
	type AuthorizationCodeGrantChecks,
	type IDToken,
	type TokenEndpointResponse,
	type UserInfoResponse,
} from 'openid-client';

export interface User extends TokenEndpointResponse {
	token_claims?: IDToken;
}
const login = async (): Promise<void> => {
	const clientConfig = await oidcClient.getClientConfig();
	const code_verifier = oidcClient.randomPKCECodeVerifier();
	const code_challenge = await oidcClient.calculatePKCECodeChallenge(
		code_verifier,
	);
	const nonce = oidcClient.randomNonce();
	const parameters: URLSearchParams | Record<string, string> = {
		redirect_uri: window.location.origin + config.oidcRedirectUri,
		scope: config.oidcScope,
		code_challenge,
		code_challenge_method: 'S256',
		nonce,
	};

	localStorageService.set(localStorageKeys.PKCE_VERIFIER, code_verifier);
	localStorageService.set(localStorageKeys.OIDC_NONCE, nonce);

	const authUrl = oidcClient.buildAuthorizationUrl(clientConfig, parameters);

	window.location.href = authUrl.href;
};

const logout = async (): Promise<void> => {
	const clientConfig = await oidcClient.getClientConfig();
	const stored: string | null = localStorageService.get(
		localStorageKeys.TOKEN_SET,
	);

	if (!stored) {
		await login();
		return;
	}

	const { id_token } = JSON.parse(stored);

	const redirectTo = oidcClient.buildEndSessionUrl(clientConfig, {
		post_logout_redirect_uri: config.oidcLogoutRedirect,
		id_token_hint: id_token,
	});
	localStorageService.set(localStorageKeys.ACCESS_TOKEN, null);
	localStorageService.set(localStorageKeys.TOKEN_SET, null);
	window.location.href = redirectTo.href;
};

const handleRedirectCallback = async (): Promise<User> => {
	const clientConfig = await oidcClient.getClientConfig();
	const currentUrl = new URL(window.location.href);
	const code_verifier = localStorageService.get(localStorageKeys.PKCE_VERIFIER);
	const expectedNonce = localStorageService.get(localStorageKeys.OIDC_NONCE);
	const tokens = await oidcClient.authorizationCodeGrant(
		clientConfig,
		currentUrl,
		{
			pkceCodeVerifier: code_verifier,
			expectedNonce,
			idTokenExpected: true,
		} as AuthorizationCodeGrantChecks,
	);

	localStorageService.set(
		localStorageKeys.TOKEN_SET,
		JSON.stringify({
			...tokens,
			token_claims: tokens.claims(),
		}),
	);
	localStorageService.set(localStorageKeys.PKCE_VERIFIER, null);
	localStorageService.set(localStorageKeys.OIDC_NONCE, null);
	const token_claims = tokens.claims();

	return { ...tokens, ...token_claims };
};

const refreshToken = async (): Promise<boolean> => {
	const clientConfig = await oidcClient.getClientConfig();
	const stored: string | null = localStorageService.get(
		localStorageKeys.TOKEN_SET,
	);

	if (!stored) return false;

	const { refresh_token } = JSON.parse(stored);

	try {
		const tokens = await oidcClient.refreshTokenGrant(
			clientConfig,
			refresh_token,
		);

		localStorageService.set(localStorageKeys.ACCESS_TOKEN, tokens.access_token);

		localStorageService.set(
			localStorageKeys.TOKEN_SET,
			JSON.stringify({
				...tokens,
				token_claims: tokens.claims(),
			}),
		);

		return true;
	} catch (e) {
		return false;
	}
};

const updateUserInfoFromOIDC = async (): Promise<UserInfoResponse | null> => {
	const clientConfig = await oidcClient.getClientConfig();
	const stored: string | null = localStorageService.get(
		localStorageKeys.TOKEN_SET,
	);

	if (!stored) return null;

	const { token_claims, access_token } = JSON.parse(stored);
	return await oidcClient.fetchUserInfo(
		clientConfig,
		access_token,
		token_claims.sub,
	);
};

const getTokenSet = (): User | null => {
	const stored: string | null = localStorageService.get(
		localStorageKeys.TOKEN_SET,
	);

	if (!stored) return null;

	return JSON.parse(stored);
};

const oidcAuthService = {
	login,
	handleRedirectCallback,
	updateUserInfoFromOIDC,
	getTokenSet,
	refreshToken,
	logout,
};

export default oidcAuthService;
