import type {
	IPaginateResponse,
	ITableSettings,
	Nullable,
} from '../../shared/types';

enum SettingsTab {
	BASIC_DATA = 'main',
	CHANGE_PASSWORD = 'change-password',
	EMPLOYEES = 'employees',
}

interface ISettingsService {
	getSettings: () => Promise<ISettingsData>;
	setSettings: (settings: ISettingsData) => Promise<ISettingsData>;
	updatePassword: (data: IChangePasswordData) => Promise<void>;
	getTwoStepData: (enableTwoFactor: boolean) => Promise<ITwoStepData>;
	confirmTwoStep: (code: string) => Promise<{ recoveryCodes: string[] }>;
	getRecoveryCodes: () => Promise<{ recoveryCodes: string[] }>;
	getEmployees: (
		settings: ITableSettings,
	) => Promise<IPaginateResponse<IMerchantUser>>;
	createEmployee: (data: IMerchantUser) => Promise<IMerchantUser>;
	getEmployee: (id: string) => Promise<IMerchantUser>;
	updateEmployee: (id: string, data: IMerchantUser) => Promise<IMerchantUser>;
}

interface ISettingsData {
	userName: string;
	email: string;
	phoneNumber: string;
	twoStepIdentificationEnabled: boolean;
}

interface ITwoStepData {
	qrCodeUri: string;
	sharedKey: string;
}

interface IChangePasswordData {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}

interface IMerchantUser {
	id?: string;
	email: string;
	userName: string;
	roleName: string;
	roleValue: string;
	phoneNumber?: string;
	phone: string;
	password?: string;
	repeatPassword?: string;
	status: number;
	permissions: Permission[];
	createdAt: string;
	updatedAt: string;
	createdBy: string;
	updatedBy: string;
}

export type Permission = {
	accountId: Nullable<string>;
	accountName: Nullable<string>;
	permissionsNames: string[];
};

enum AuthStep {
	REGISTER = 1,
	VERIFICATION = 2,
	COPY = 3,
}

export { SettingsTab, AuthStep };

export type {
	ISettingsData,
	IChangePasswordData,
	IMerchantUser,
	ISettingsService,
	ITwoStepData,
};
