import React, { type FC, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { useAppDispatch } from 'shared/hooks';
import { Home, Contacts } from 'modules/home';
import { RedirectCallback, AuthWrapper } from 'modules/auth';
import { NotFound } from 'modules/404';
import { MainContainer } from 'modules/layout';
import { Accounts, Account } from 'modules/accounts';
import { addHistoryRecord } from 'modules/app';
import { Operations, Operation } from 'modules/operations';
import { Settings } from 'modules/settings';
import RequestConnection from 'modules/accounts/components/RequestConnection';
import { Withdrawals, Withdrawal, CreateWithdrawal } from 'modules/withdrawals';

const Navigation: FC = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	useEffect(() => {
		const tabs: string[] = [
			'main',
			'wallets',
			'services',
			'display-settings',
			'audit',
			'users',
			'balance',
			'wallet-data',
			'transactions',
			'lock',
			'employees',
			'change-password',
			'connection-type',
		];
		const last = location.pathname.split('/').pop();
		if (!last || tabs.includes(last)) return;
		dispatch(addHistoryRecord(location.pathname));
	}, [dispatch, location.pathname]);

	return (
		<AuthWrapper>
			<Routes>
				<Route path={routes.auth.redirect} element={<RedirectCallback />} />
				<Route path={routes.home} element={<Home />} />
				<Route path={routes.contacts} element={<Contacts />} />
				<Route path="/" element={<MainContainer />}>
					<Route path={routes.accounts.index} element={<Accounts />} />
					<Route path={routes.accounts.create} element={<Account />} />
					<Route
						path={routes.accounts.details(':id/:tab')}
						element={<Account />}
					/>
					<Route
						path={routes.accounts.details(':id/:tab/create')}
						element={<RequestConnection />}
					/>
					<Route path={routes.operations.index} element={<Operations />} />
					<Route
						path={routes.operations.operation(':id/:tab')}
						element={<Operation />}
					/>
					<Route path={routes.settings.index(':tab')} element={<Settings />} />
					<Route
						path={routes.settings.index(':tab/:userId')}
						element={<Settings />}
					/>
					<Route path={routes.withdrawals.index} element={<Withdrawals />} />
					<Route
						path={routes.withdrawals.withdrawal(':id/:tab')}
						element={<Withdrawal />}
					/>
					<Route
						path={routes.withdrawals.create}
						element={<CreateWithdrawal />}
					/>
					<Route path={routes.doesntExist} element={<NotFound />} />
				</Route>
			</Routes>
		</AuthWrapper>
	);
};

export default Navigation;
