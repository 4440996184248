import {
	type IChangePasswordData,
	type IMerchantUser,
	type ISettingsData,
	type ISettingsService,
	type ITwoStepData,
} from '../types';
import axiosInstance from 'shared/libs/axios';
import api from 'shared/constants/api';
import type { IPaginateResponse, ITableSettings } from 'shared/types';
import { settingsToSearchParams } from 'shared/helpers';

class SettingsService implements ISettingsService {
	async getSettings(): Promise<ISettingsData> {
		return (await axiosInstance.get(api.SETTINGS.GET_SETTINGS)).data;
	}

	async setSettings(settings: ISettingsData): Promise<ISettingsData> {
		return (
			await axiosInstance.put<ISettingsData>(
				api.SETTINGS.UPDATE_SETTINGS,
				settings,
			)
		).data;
	}

	async updatePassword(data: IChangePasswordData): Promise<void> {
		return (await axiosInstance.put(api.SETTINGS.UPDATE_PASSWORD, data)).data;
	}

	async getTwoStepData(enableTwoFactor: boolean): Promise<ITwoStepData> {
		return (
			await axiosInstance.put(api.SETTINGS.UPDATE_TWO_FACTOR, {
				enableTwoFactor,
			})
		).data;
	}

	async confirmTwoStep(code: string): Promise<{ recoveryCodes: string[] }> {
		return (await axiosInstance.post(api.SETTINGS.CONFIRM_TWO_FACTOR, { code }))
			.data;
	}

	async getRecoveryCodes(): Promise<{ recoveryCodes: string[] }> {
		return (await axiosInstance.post(api.SETTINGS.GET_RECOVERY_CODES, {})).data;
	}

	async getEmployees(
		settings: ITableSettings,
	): Promise<IPaginateResponse<IMerchantUser>> {
		const query = settingsToSearchParams(settings);
		return (
			await axiosInstance.get<IPaginateResponse<IMerchantUser>>(
				api.SETTINGS.GET_EMPLOYEES + `?${query}`,
			)
		).data;
	}

	async createEmployee(data: IMerchantUser): Promise<IMerchantUser> {
		return (
			await axiosInstance.post<IMerchantUser>(
				api.SETTINGS.CREATE_EMPLOYEE,
				data,
			)
		).data;
	}

	async getEmployee(id: string): Promise<IMerchantUser> {
		return (
			await axiosInstance.get<IMerchantUser>(api.SETTINGS.GET_EMPLOYEE(id))
		).data;
	}

	async updateEmployee(
		id: string,
		data: IMerchantUser,
	): Promise<IMerchantUser> {
		return (
			await axiosInstance.put<IMerchantUser>(
				api.SETTINGS.UPDATE_EMPLOYEE(id),
				data,
			)
		).data;
	}
}
export default new SettingsService();
