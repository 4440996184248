import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ITableSettings } from 'shared/types';
import {
	setSelectedMerchantEmployee,
	setSelectedMerchantUsersRows,
	setSelectedMerchantUsersTotalPages,
} from './reducer';
import SettingsService from '../service';
import { type IMerchantUser } from '../types';

const LOAD_EMPLOYEES = 'employees/load-all';
const LOAD_EMPLOYEE = 'employees/load-one';
const CREATE_EMPLOYEE = 'employees/create';
const UPDATE_EMPLOYEE = 'employees/update';

const loadEmployeesAction = createAsyncThunk(
	LOAD_EMPLOYEES,
	async (settings: ITableSettings, { rejectWithValue, dispatch }) => {
		try {
			const {
				content,
				itemsCount,
				paging: { pageSize },
			} = await SettingsService.getEmployees(settings);

			dispatch(setSelectedMerchantUsersRows(content));
			dispatch(
				setSelectedMerchantUsersTotalPages(
					Math.ceil(itemsCount / pageSize) || 1,
				),
			);
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const loadEmployeeAction = createAsyncThunk(
	LOAD_EMPLOYEE,
	async (id: string, { rejectWithValue, dispatch }) => {
		try {
			const employee = await SettingsService.getEmployee(id);
			dispatch(setSelectedMerchantEmployee(employee));
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const createEmployeeAction = createAsyncThunk(
	CREATE_EMPLOYEE,
	async (data: IMerchantUser, { rejectWithValue, dispatch }) => {
		try {
			const employee = await SettingsService.createEmployee(data);
			dispatch(setSelectedMerchantEmployee(employee));
			return employee;
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const updateEmployeeAction = createAsyncThunk(
	UPDATE_EMPLOYEE,
	async (
		{ id, data }: { id: string; data: IMerchantUser },
		{ rejectWithValue, dispatch },
	) => {
		try {
			const employee = await SettingsService.updateEmployee(id, data);
			dispatch(setSelectedMerchantEmployee(employee));
			return employee;
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export {
	loadEmployeesAction,
	loadEmployeeAction,
	createEmployeeAction,
	updateEmployeeAction,
};
