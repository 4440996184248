import React, { type FC, useCallback, useMemo } from 'react';
import { type Permission as IPermission } from '../types';
import { type Permission as PermissionEnum } from 'modules/app/types';
import { Checkbox } from 'components/UI';
import { useTranslation } from 'react-i18next';

const accountKeys = [
	'client_can_view_account',
	'client_can_edit_account',
	'client_can_view_balances',
];

const withdrawalKeys = [
	'client_can_view_withdrawals',
	'client_can_create_regular_withdrawals',
	'client_can_create_bulk_withdrawals',
	'client_can_create_transfers',
];
const paymentKeys = ['client_can_view_payments', 'client_can_create_invoices'];

const partnershipKeys = ['client_can_view_partnership'];

interface IProps {
	isEditing: boolean;
	account: IPermission;
	permissionList: PermissionEnum[];
	onChange: (
		accountId: string,
		accountName: string,
		permissionValue: string,
	) => void;
}

const Permission: FC<IProps> = ({
	account,
	isEditing,
	permissionList,
	onChange,
}) => {
	const { t } = useTranslation();

	const mappedPermissions = useMemo<
		Array<{
			title: string;
			list: Array<{
				name: string;
				value: string;
			}>;
		}>
	>(() => {
		return [
			{
				title: t('modules.settings.employees.account'),
				list: accountKeys.map(
					key =>
						permissionList.find(({ name }) => name === key) as {
							name: string;
							value: string;
						},
				),
			},
			{
				title: t('modules.settings.employees.withdrawal'),
				list: withdrawalKeys.map(
					key =>
						permissionList.find(({ name }) => name === key) as {
							name: string;
							value: string;
						},
				),
			},
			{
				title: t('modules.settings.employees.payment'),
				list: paymentKeys.map(
					key =>
						permissionList.find(({ name }) => name === key) as {
							name: string;
							value: string;
						},
				),
			},
			{
				title: t('modules.settings.employees.partnership'),
				list: partnershipKeys.map(
					key =>
						permissionList.find(({ name }) => name === key) as {
							name: string;
							value: string;
						},
				),
			},
		];
	}, [permissionList, t]);

	const handlePermissionChange = useCallback(
		(accountId: string, accountName: string, permissionValue: string) => {
			const isDisabled = !account.permissionsNames.some(
				permission => permission === permissionValue,
			);
			if (!isDisabled) {
				onChange(accountId, accountName, permissionValue);
				return;
			}

			switch (permissionValue) {
				case 'client_can_create_invoices': {
					if (!account.permissionsNames.includes('client_can_view_payments')) {
						onChange(accountId, accountName, 'client_can_view_payments');
					}

					onChange(accountId, accountName, permissionValue);
					break;
				}

				case 'client_can_create_regular_withdrawals':
				case 'client_can_create_bulk_withdrawals':
				case 'client_can_create_transfers': {
					if (
						!account.permissionsNames.includes('client_can_view_withdrawals')
					) {
						onChange(accountId, accountName, 'client_can_view_withdrawals');
					}

					onChange(accountId, accountName, permissionValue);
					break;
				}

				default:
					onChange(accountId, accountName, permissionValue);
			}
		},
		[onChange, account.permissionsNames],
	);

	// Dont render general permissions block
	if (!account.accountId) return <></>;

	return (
		<div className="py-5 border-b border-b-gray-200">
			<h4 className="text-gray-900 font-medium text-lg mb-6">
				{account.accountName}
			</h4>
			<div className="grid grid-cols-2 justify-between gap-x-4 gap-y-8">
				{mappedPermissions.map(permission => (
					<h5
						className="text-sm leading-5 text-gray-700 font-normal flex justify-between"
						key={permission.title}>
						<span className="flex-[0.4]">{permission.title}</span>
						<div className="flex-1">
							{permission.list.map(item => (
								<Checkbox
									disabled={!isEditing}
									key={item.name}
									label={item.value}
									checked={account.permissionsNames.some(
										permission => permission === item.name,
									)}
									onChange={() => {
										handlePermissionChange(
											String(account.accountId),
											String(account.accountName),
											item.name,
										);
									}}
								/>
							))}
						</div>
					</h5>
				))}
			</div>
		</div>
	);
};

export default Permission;
