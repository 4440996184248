import React, { type FC, useMemo } from 'react';
import clsx from 'clsx';
import { type ICellRendererParams } from 'ag-grid-community';
import { useAppSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

const EnumRenderer: FC<ICellRendererParams<unknown>> = ({ value, colDef }) => {
	const { enums, currencies, roles } = useAppSelector(state => state.app);
	const { t } = useTranslation();

	const displayText = useMemo<string>(() => {
		if (!enums) return String(value);
		const key = colDef?.refData?.enumkey ?? '';
		if (key === 'BooleanToTextEnum') {
			return value ? t('ui.table.yes') : t('ui.table.no');
		}

		if (
			colDef?.field === 'fromCurrencyId' ||
			colDef?.field === 'currencyId' ||
			colDef?.field === 'toCurrencyId'
		) {
			return (
				currencies.find(currency => currency.id === value)?.code ??
				String(value)
			);
		}

		if (key === 'role') {
			const role = roles.find(role => role.name === value);
			return role?.value ?? '';
		}

		return enums[key]?.values[value]?.value ?? value;
	}, [
		colDef?.field,
		colDef?.refData?.enumkey,
		currencies,
		enums,
		t,
		value,
		roles,
	]);

	const statusId = useMemo<number>(() => {
		if (!enums || colDef?.field !== 'status') return 0;
		const key = colDef?.refData?.enumkey ?? '';
		const status = Object.values(enums[key].values).find(
			val => val.value === value || val.id === value,
		);
		return status?.id ?? 0;
	}, [colDef?.field, colDef?.refData?.enumkey, enums, value]);

	const classes = useMemo<string>(() => {
		const key = colDef?.refData?.enumkey ?? '';

		const successClasses = 'text-green-800 bg-green-100';
		const createdClasses = 'text-main bg-blue-200';
		const pendingClasses = 'text-orange-800 bg-orange-100';
		const errorClasses = 'text-red-800 bg-red-100';
		const defaultClasses = 'text-gray-500 !text-sm !font-normal !px-0';

		if (key === 'role') {
			const role = roles.find(role => role.name === value);
			switch (role?.name) {
				case 'client_admin':
					return createdClasses;
				case 'client_operator':
					return pendingClasses;
				default:
					return defaultClasses;
			}
		}

		if (key === 'OperationStatus') {
			switch (statusId) {
				case 10:
					return createdClasses;
				case 40:
				case 110:
				case 120:
					return successClasses;
				case 50:
				case 90:
				case 100:
					return pendingClasses;
				default:
					return errorClasses;
			}
		}

		if (key === 'TransactionStatus') {
			switch (statusId) {
				case 30:
					return successClasses;
				case 10:
				case 20:
				case 70:
					return pendingClasses;
				case 80:
					return createdClasses;
				default:
					return errorClasses;
			}
		}

		if (key === 'WalletStatus') {
			switch (statusId) {
				case 10:
					return successClasses;
				case 30:
					return pendingClasses;
				default:
					return errorClasses;
			}
		}

		if (key === 'UserStatus') {
			switch (statusId) {
				case 10:
					return successClasses;
				case 30:
					return pendingClasses;
				default:
					return errorClasses;
			}
		}

		return defaultClasses;
	}, [colDef, statusId]);
	return (
		<div className="h-full flex py-5">
			<span
				className={clsx(
					'py-0.5 px-2.5 rounded-xl font-medium text-xs',
					classes,
				)}>
				{displayText}
			</span>
		</div>
	);
};

export default EnumRenderer;
