const LANGUAGE = 'LOCALSTORAGE_LANGUAGE';
const ACCESS_TOKEN = 'accessToken';
const ROUT_BEFORE_AUTH = 'routBeforeAuth';
const PKCE_VERIFIER = 'PKCE_VERIFIER';
const OIDC_NONCE = 'OIDC_NONCE';
const TOKEN_SET = 'TOKEN_SET';
const FIRST_LOGIN_SHOWN = 'FIRST_LOGIN_SHOWN';

export const localStorageKeys = {
	LANGUAGE,
	ACCESS_TOKEN,
	ROUT_BEFORE_AUTH,
	PKCE_VERIFIER,
	OIDC_NONCE,
	TOKEN_SET,
	FIRST_LOGIN_SHOWN,
};
