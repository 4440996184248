import React, {
	type FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { type Nullable } from 'shared/types';
import { removeHistoryRecord } from 'modules/app';
import routes from 'shared/constants/routes';
import { useAppDispatch, useAppSelector, useValidation } from 'shared/hooks';
import { Button, Checkbox, FormRow, Input, Select } from 'components/UI';
import { type Option } from 'components/UI/Select/types';
import { type IMerchantUser, SettingsTab } from '../types';
import {
	createEmployeeAction,
	loadEmployeeAction,
	updateEmployeeAction,
} from '../store/actions';
import { initUserData } from '../constants';
import Permission from './Permission';
import AccountService from 'modules/accounts/service';
import clsx from 'clsx';
import { setSelectedMerchantEmployee } from '../store/reducer';

const SelectedEmployeeTab: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const {
		stringValidation,
		emailValidation,
		passwordValidation,
		phoneNumberValidation,
	} = useValidation();
	const { roles, permissions, enums } = useAppSelector(state => state.app);

	const { userId } = useParams();
	const user = useAppSelector(state => state.settings.employees.selected);
	const isCreating = useMemo<boolean>(() => userId === 'create', [userId]);
	const [isEditing, setIsEditing] = useState<boolean>(Boolean(isCreating));

	const toggleEditing = useCallback(() => {
		setIsEditing(prev => !prev);
	}, []);

	const {
		watch,
		setValue,
		register,
		clearErrors,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IMerchantUser>({
		mode: 'all',
		defaultValues: initUserData,
	});
	const watcher = watch();

	const fillForm = useCallback(() => {
		if (!user) return;
		Object.entries(user).forEach(([key, value]) => {
			setValue(
				key as keyof IMerchantUser,
				isCreating ? (key === 'permissions' ? [] : '') : value,
				{
					shouldTouch: false,
				},
			);
		});
		clearErrors();
	}, [user, clearErrors, setValue, isCreating]);

	const [selectedRole, setSelectedRole] = useState<Nullable<Option>>(null);

	const rolesOptions = useMemo<Option[]>(() => {
		return roles.map(({ name, value }) => ({ value: name, label: value }));
	}, [roles]);

	const [selectedStatus, setSelectedStatus] = useState<Nullable<Option>>(null);

	const statusOptions = useMemo<Option[]>(() => {
		if (!enums) return [];
		const options = Object.values(enums.UserStatus.values).map(
			({ id, value }) => ({
				value: id,
				label: value,
			}),
		);
		return isCreating
			? options.filter(option => Number(option.value) !== 20)
			: options;
	}, [enums, isCreating]);

	const handleStatusChange = useCallback(
		(status: Option) => {
			setValue('status', Number(status.value));
			setSelectedStatus(status);
		},
		[setValue],
	);

	const handleCancel = useCallback(() => {
		fillForm();
		toggleEditing();
		clearErrors();
		if (isCreating) navigate(-1);
	}, [clearErrors, fillForm, isCreating, navigate, toggleEditing]);

	const handleGoBack = useCallback(() => {
		dispatch(removeHistoryRecord());
	}, [dispatch]);

	const handleConfirm = useCallback(
		async (newUser: IMerchantUser) => {
			try {
				if (isCreating) {
					const created = await dispatch(
						createEmployeeAction(newUser),
					).unwrap();
					navigate(routes.settings.employee(String(created.id)));
					toggleEditing();
					return;
				}

				if (!userId) return;

				await dispatch(
					updateEmployeeAction({
						id: userId,
						data: newUser,
					}),
				);
				toggleEditing();
			} catch (e) {
				fillForm();
			}
		},
		[dispatch, fillForm, isCreating, navigate, userId, toggleEditing],
	);

	const createAccountPermission = useMemo(() => {
		if (!permissions) return null;
		return (
			permissions.find(
				permission => permission.name === 'client_can_create_accounts',
			) ?? null
		);
	}, [permissions]);

	const createAccountPermissionValue = useMemo<boolean>(() => {
		if (!createAccountPermission) return false;
		const general = watcher.permissions.find(
			permission => !permission.accountId,
		);
		if (!general) return false;
		return general.permissionsNames?.includes(createAccountPermission.name);
	}, [createAccountPermission, watcher]);

	const handleTogglePermission = useCallback(
		(
			accountId: Nullable<string>,
			accountName: Nullable<string>,
			permissionValue: string,
		) => {
			const existedPermissions = watcher.permissions;
			const selectedPermissionIndex = existedPermissions.findIndex(
				permission =>
					permission.accountName === accountName &&
					permission.accountId === accountId,
			);
			const isEnabled = Boolean(
				existedPermissions
					.find(
						permission =>
							permission.accountName === accountName &&
							permission.accountId === accountId,
					)
					?.permissionsNames.find(permission => permission === permissionValue),
			);

			if (selectedPermissionIndex === -1) {
				setValue('permissions', [
					...existedPermissions,
					{
						accountId,
						accountName,
						permissionsNames: [permissionValue],
					},
				]);
				return;
			}

			const selected = existedPermissions[selectedPermissionIndex];

			setValue(`permissions.${selectedPermissionIndex}`, {
				accountId,
				accountName,
				permissionsNames: isEnabled
					? selected.permissionsNames.filter(
							permission => permission !== permissionValue,
					  )
					: [...selected.permissionsNames, permissionValue],
			});
		},
		[setValue, watcher.permissions],
	);

	const handleRoleChange = useCallback(
		(status: Option) => {
			setValue('roleName', String(status.value));
			setValue('roleValue', String(status.label));
			setSelectedRole(status);
			const general = watcher.permissions.find(
				permission => !permission.accountId,
			);
			const updated = watcher.permissions.map(permission => {
				if (!permission.accountId)
					return {
						...permission,
						permissionsNames:
							status.value === 'client_admin'
								? ['client_can_create_accounts']
								: [],
					};

				return {
					...permission,
					permissionsNames:
						status.value === 'client_admin'
							? ['client_can_view_payments', 'client_can_view_account']
							: ['client_can_view_payments'],
				};
			});
			if (!general) {
				setValue('permissions', [
					{
						accountId: null,
						accountName: null,
						permissionsNames:
							status.value === 'client_admin'
								? ['client_can_create_accounts']
								: [],
					},
					...updated,
				]);
				return;
			}

			setValue('permissions', updated);
		},
		[setValue, watcher.permissions],
	);

	const [accountOptions, setAccountOptions] = useState<Option[]>([]);
	const selectedAccount = useMemo<Option>(
		() => ({
			value: '',
			label: t('modules.settings.employees.chooseAccount'),
		}),
		[t],
	);

	const loadAccounts = useCallback(async () => {
		try {
			const accounts = await AccountService.getAccountsData();
			setAccountOptions(
				accounts.map(account => ({
					label: account.title,
					value: account.id,
				})),
			);
		} catch (e) {
			console.log(e);
		}
	}, []);

	const handleAccountSelect = useCallback(
		({ value, label }: Option) => {
			const accounts = watcher.permissions;
			if (accounts.some(account => account.accountId === String(value))) return;
			const selectedPermissions =
				watcher.roleName === 'client_admin'
					? ['client_can_view_payments', 'client_can_view_account']
					: ['client_can_view_payments'];
			setValue('permissions', [
				...accounts,
				{
					accountId: String(value),
					accountName: label,
					permissionsNames: selectedPermissions,
				},
			]);
		},
		[watcher.permissions, watcher.roleName, setValue],
	);

	const handleRemoveAccount = useCallback(
		(id: string) => {
			const accounts = watcher.permissions;
			setValue(
				'permissions',
				accounts.filter(account => account.accountId !== id),
			);
		},
		[setValue, watcher.permissions],
	);

	useEffect(() => {
		if (!userId) {
			navigate(-1);
			return;
		}

		if (isCreating) return;

		void dispatch(loadEmployeeAction(userId));
	}, [dispatch, isCreating, navigate, userId]);

	useEffect(fillForm, [fillForm]);

	useEffect(() => {
		void loadAccounts();
		return () => {
			dispatch(setSelectedMerchantEmployee(null));
		};
	}, [dispatch, loadAccounts]);

	useEffect(() => {
		// Set initial role
		if (selectedRole) return;
		const role: Nullable<Option> =
			rolesOptions.find(option => option.value === watcher.roleName) ?? null;
		setSelectedRole(role);
	}, [rolesOptions, selectedRole, watcher.roleName]);

	useEffect(() => {
		// Set initial status
		if (selectedStatus) return;
		const status: Nullable<Option> =
			statusOptions.find(option => option.value === watcher.status) ?? null;
		setSelectedStatus(status);
	}, [selectedStatus, statusOptions, watcher.status]);

	return (
		<div>
			<Link
				className="text-main font-medium text-sm mb-6"
				onClick={handleGoBack}
				to={routes.settings.index(SettingsTab.EMPLOYEES)}>
				{t('ui.table.backToTable')}
			</Link>
			<div className="flex justify-between items-center py-2">
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{isCreating
						? t('modules.settings.employees.create')
						: t('modules.settings.employees.settings')}
				</h4>
				{!isEditing && (
					<Button
						data-test-id="client-employee-edit"
						variant="secondary"
						onClick={toggleEditing}>
						<EditIcon className="mr-2.5" />
						{t('ui.buttons.edit')}
					</Button>
				)}
			</div>
			<FormRow
				label={`${t('modules.settings.employees.fields.name')} ${
					isCreating ? '*' : ''
				}`}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.userName)}
					errorText={errors.userName?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('userName', stringValidation)}
				/>
			</FormRow>
			<FormRow
				label={`${t('modules.settings.employees.fields.email')} ${
					isCreating ? '*' : ''
				}`}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.email)}
					errorText={errors.email?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('email', emailValidation)}
				/>
			</FormRow>
			<FormRow label={t('modules.settings.employees.fields.phone')}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.phone)}
					errorText={errors.phone?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('phone', {
						...phoneNumberValidation,
						required: false,
					})}
				/>
			</FormRow>

			{isEditing && (
				<FormRow
					label={`${t('modules.settings.employees.fields.password')} ${
						isCreating ? '*' : ''
					}`}>
					<Input
						type="password"
						disabled={!isEditing}
						isError={Boolean(errors.password)}
						errorText={errors.password?.message ?? ''}
						wrapperClassName="max-w-[512px] w-full"
						{...register(
							'password',
							isCreating
								? passwordValidation
								: {
										...passwordValidation,
										required: false,
								  },
						)}
					/>
				</FormRow>
			)}
			{isEditing && (
				<FormRow
					label={`${t('modules.settings.employees.fields.repeat')} ${
						isCreating ? '*' : ''
					}`}>
					<Input
						type="password"
						disabled={!isEditing}
						isError={Boolean(errors.repeatPassword)}
						errorText={errors.repeatPassword?.message ?? ''}
						wrapperClassName="max-w-[512px] w-full"
						{...register(
							'repeatPassword',
							isCreating
								? passwordValidation
								: {
										...passwordValidation,
										required: false,
								  },
						)}
					/>
				</FormRow>
			)}
			<FormRow
				label={`${t('modules.settings.employees.fields.status')} ${
					isCreating ? '*' : ''
				}`}>
				<Select
					disabled={!isEditing}
					options={statusOptions}
					value={selectedStatus}
					onChange={handleStatusChange}
					optionClassName="h-[38px] flex items-center justify-center"
					wrapperClassName="max-w-[512px] w-full"
					className="h-[38px]"
				/>
			</FormRow>

			<FormRow label={t('modules.settings.employees.fields.general')}>
				<div className="flex items-center w-full max-w-[512px]">
					<Checkbox
						onChange={() => {
							handleTogglePermission(
								null,
								null,
								createAccountPermission?.name ?? '',
							);
						}}
						checked={createAccountPermissionValue}
						data-test-id="client-merchants-canCreateAccount"
						disabled={!isEditing}
						label={t('modules.settings.employees.fields.canCreateAccount')}
					/>
				</div>
			</FormRow>

			<FormRow
				label={`${t('modules.settings.employees.fields.role')} ${
					isCreating ? '*' : ''
				}`}>
				<Select
					placeholder={t('modules.settings.employees.placeholders.role')}
					disabled={!isEditing}
					options={rolesOptions}
					value={selectedRole}
					onChange={handleRoleChange}
					optionClassName="h-[38px] flex items-center justify-center"
					wrapperClassName="max-w-[512px] w-full"
					className="h-[38px]"
				/>
			</FormRow>

			<FormRow label={t('modules.settings.employees.fields.accounts')}>
				<div className="max-w-[512px] w-full">
					<div className="flex items-center flex-wrap gap-y-1.5 gap-x-1.5">
						{watcher.permissions?.map(account =>
							account.accountId ? (
								<div
									className={clsx(
										'flex items-center border  border-gray-300 rounded shadow-sm px-4 py-2',
										isEditing ? 'bg-white mb-2' : 'bg-gray-200',
									)}
									key={account.accountId + 'button'}>
									<span className="text-sm text-gray-700 leading-5 font-medium">
										{account.accountName}
									</span>
									{isEditing && (
										<svg
											onClick={() => {
												handleRemoveAccount(String(account.accountId));
											}}
											className="cursor-pointer ml-2"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M5 15L15 5M5 5L15 15"
												stroke="#6B7280"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									)}
								</div>
							) : (
								<></>
							),
						)}
					</div>
					{isEditing && (
						<Select
							testId={'account-merchant'}
							options={accountOptions}
							value={selectedAccount}
							onChange={handleAccountSelect}
							optionClassName="h-[38px] flex items-center justify-center"
							wrapperClassName="max-w-[512px] w-full"
							className="h-[38px]"
						/>
					)}
				</div>
			</FormRow>

			{watcher?.permissions?.map((account, idx) => (
				<Permission
					permissionList={permissions}
					onChange={handleTogglePermission}
					key={account.accountId}
					account={account}
					isEditing={isEditing}
				/>
			))}

			{isEditing && (
				<div className="flex justify-between items-center mt-6">
					<Button variant="secondary" onClick={handleCancel}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button
						variant="primary"
						onClick={handleSubmit(handleConfirm)}
						disabled={!isValid || !selectedRole || !selectedStatus}>
						{t('ui.buttons.confirm')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default SelectedEmployeeTab;
