import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	localStorageKeys,
	localStorageService,
} from 'shared/utils/localStorage';
import { config } from 'shared/constants/config';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import routes from 'shared/constants/routes';
import { setHas2fa } from '../../app/store/reducer';
import oidcAuthService, { type User } from '../service/oidcAuthService';

type ReturnType = {
	login: () => void;
	logout: () => void;
	signup: () => void;
	getUser: () => Promise<User | null>;
	redirectCallback: () => Promise<void>;
	refreshToken: () => Promise<void>;
	user: User | null;
};

type Chanel = {
	name: string;
	message: string;
};

const clientChanel: Chanel = {
	name: 'client-cabinet',
	message: 'logout',
};
const tabId = Date.now();
let isFetchingUser = false;

const publicRoutes = [routes.auth.redirect, routes.home, routes.contacts];
export const useAuth = (): ReturnType => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { isInited } = useAppSelector(state => state.app);

	const [user, setUser] = useState<User | null>(null);

	const chanel = useRef(new BroadcastChannel(clientChanel.name));

	const login = useCallback(async (): Promise<void> => {
		await oidcAuthService.login();
	}, []);

	const signup = useCallback(async (): Promise<void> => {
		window.location.href = `${
			config.oidcAuthority
		}/account/register?ReturnUrl=${
			window.location.origin + config.oidcRedirectUri
		}`;
	}, []);

	const logout = useCallback(async (): Promise<void> => {
		chanel.current.postMessage({
			message: clientChanel.message,
			id: tabId,
		});
		await oidcAuthService.logout();
	}, []);

	const redirectCallback = useCallback(async (): Promise<void> => {
		try {
			const currentUserInfo = await oidcAuthService.handleRedirectCallback();
			const user = oidcAuthService.getTokenSet();

			if (user?.scope?.includes('admincabinet')) {
				await logout();
				return;
			}

			localStorageService.set(
				localStorageKeys.ACCESS_TOKEN,
				currentUserInfo.access_token,
			);
			setUser(currentUserInfo);
			const rout = localStorageService.get<string>(
				localStorageKeys.ROUT_BEFORE_AUTH,
			);
			navigate(rout ?? routes.accounts.index);
			setTimeout(() => {
				localStorageService.set(localStorageKeys.ROUT_BEFORE_AUTH, null);
			}, 1000);
		} catch (e) {
			console.log(e);
			await login();
		}
	}, [navigate, logout, login]);

	const getUser = useCallback(async (): Promise<User | null> => {
		if (user) return user;
		const newUser = oidcAuthService.getTokenSet();
		setUser(newUser);
		return newUser;
	}, [user]);

	const refreshToken = useCallback(async () => {
		const isSuccessFull = await oidcAuthService.refreshToken();
		const newUser = oidcAuthService.getTokenSet();
		setUser(newUser);
		if (!isSuccessFull) await logout();
	}, [logout]);

	const checkUser = useCallback(async () => {
		if (publicRoutes.includes(location.pathname) || isFetchingUser) return;
		isFetchingUser = true;
		const user = oidcAuthService.getTokenSet();
		if (!user) {
			await logout();
			return;
		}

		if (user?.scope?.includes('admincabinet')) await logout();
		await refreshToken();
		isFetchingUser = false;
	}, [logout, refreshToken]);

	useEffect(() => {
		if (isInited) return;
		void checkUser();
		chanel.current.onmessage = function (ev) {
			if (ev.data.message === clientChanel.message && tabId !== ev.data.id) {
				localStorageService.set(localStorageKeys.ACCESS_TOKEN, null);
				localStorageService.set(localStorageKeys.TOKEN_SET, null);
			}
		};
		// eslint-disable-next-line
	}, [location.pathname]);

	useEffect(() => {
		if (!user) return;
		dispatch(setHas2fa(user.token_claims?.has_2fa as boolean));
	}, [user]);

	return {
		login,
		signup,
		redirectCallback,
		user,
		getUser,
		logout,
		refreshToken,
	};
};
