import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	setCurrencies,
	setEnums,
	setInited,
	setLoading,
	setPermissions,
	setRoles,
	setServices,
	setUserPermissions,
	setUserRole,
} from './reducer';
import AppService from '../service';
import { type Enum, type TransformedEnum } from '../types';
import { type IDToken } from 'openid-client';

const INIT_TYPE = 'app/init';
const USER_SETUP_TYPE = 'app/user-setup';
const initAppAction = createAsyncThunk(
	INIT_TYPE,
	async (data, { rejectWithValue, dispatch }) => {
		try {
			dispatch(setLoading(true));

			const enums = await AppService.getEnums();
			const transformArrayToMap = (
				list: Enum[],
				key: keyof Enum,
			): TransformedEnum => {
				return list.reduce(
					(accum: object, item: Enum) => ({
						...accum,
						[item[key] as string]: {
							...item,
							values: item.values.reduce((accum, item) => {
								return { ...accum, [item.id]: item };
							}, {}),
						},
					}),
					{},
				);
			};

			dispatch(setEnums(transformArrayToMap(enums, 'name')));
			dispatch(setServices(await AppService.getServices()));
			dispatch(setCurrencies(await AppService.getCurrencies()));
			dispatch(setPermissions(await AppService.getPermissions()));
			dispatch(setRoles(await AppService.getRoles()));
			dispatch(setInited(true));
		} catch (e) {
			return rejectWithValue(e);
		} finally {
			dispatch(setLoading(false));
		}
	},
);

const userSetupAction = createAsyncThunk(
	USER_SETUP_TYPE,
	async (claims: IDToken, { rejectWithValue, dispatch }) => {
		try {
			const accountPermissions = JSON.parse(
				(claims?.account_permissions as string) ?? '[]',
			);
			const permissions = [
				{
					accountId: null,
					permissionsNames:
						typeof claims.permissions === 'string'
							? [claims.permissions]
							: (claims.permissions as string[]),
				},
				...accountPermissions.map(
					(permission: { account_id: string; permissions: string[] }) => ({
						accountId: permission.account_id,
						permissionsNames: permission.permissions,
					}),
				),
			];
			dispatch(setUserRole(claims.role as string));
			dispatch(setUserPermissions(permissions));
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export { initAppAction, userSetupAction };
