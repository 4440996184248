import { type IMerchantUser, type ISettingsData } from './types';

export const initSettings: ISettingsData = {
	userName: '',
	email: '',
	phoneNumber: '',
	twoStepIdentificationEnabled: false,
};

export const initUserData: IMerchantUser = {
	email: '',
	userName: '',
	roleName: '',
	roleValue: '',
	phoneNumber: '',
	phone: '',
	password: '',
	repeatPassword: '',
	status: 0,
	permissions: [],
	createdAt: '',
	updatedAt: '',
	createdBy: '',
	updatedBy: '',
};
