import React, {
	type FC,
	type SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Switch, Button } from 'components/UI';
import Modal from 'components/Modal';
import routes from 'shared/constants/routes';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import AccountService from './service';
import { setBillingData } from './store/reducer';
import { type Nullable } from 'shared/types';
import { AccountsTab, type IAccountsData } from './types';
import { Tooltip } from '../../components/UI';
const Accounts: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { has2fa } = useAppSelector(state => state.app);
	const { permissions, role } = useAppSelector(state => state.app.user);

	const myBillsData = useAppSelector(state => state.accounts.billingData);
	const enums = useAppSelector(state => state.app.enums);
	const [confirmationModalOpened, setConfirmationModalOpened] =
		useState<boolean>(false);
	const [confirmationModalItem, setConfirmationModalItem] =
		useState<Nullable<IAccountsData>>(null);
	const handleCloseConfirmationModal = useCallback(() => {
		setConfirmationModalOpened(false);
		setConfirmationModalItem(null);
	}, []);

	const handleConfirmStatus = useCallback(
		async (id: string) => {
			if (!myBillsData) return;
			setConfirmationModalOpened(false);
			const accountToUpdate = myBillsData.find(item => item.id === id);
			if (!accountToUpdate) return;

			await AccountService.toggleAccountsStatus(id);
			dispatch(
				setBillingData(
					myBillsData.map(item =>
						item.id === id
							? { ...item, status: item.status === 10 ? 30 : 10 }
							: item,
					),
				),
			);
		},
		[dispatch, myBillsData],
	);

	const activeStatus = useMemo<Nullable<number>>(() => {
		if (!enums) return null;
		return (
			Object.values(enums.ShopAccountStatus.values).find(row => row.id === 10)
				?.id ?? null
		);
	}, [enums]);

	const moderationStatus = useMemo<Nullable<number>>(() => {
		if (!enums) return null;
		return (
			Object.values(enums.ShopAccountStatus.values).find(row => row.id === 40)
				?.id ?? null
		);
	}, [enums]);

	const blockedStatus = useMemo<Nullable<number>>(() => {
		if (!enums) return null;
		return (
			Object.values(enums.ShopAccountStatus.values).find(row => row.id === 20)
				?.id ?? null
		);
	}, [enums]);

	const handleOpenConfirmationModal = useCallback(
		(item: IAccountsData) => {
			if (item.status !== activeStatus) {
				setConfirmationModalItem(item);
				void handleConfirmStatus(item.id);
				return;
			}

			setConfirmationModalItem(item);
			setConfirmationModalOpened(true);
		},
		[handleConfirmStatus, activeStatus],
	);

	const goToAccountDetails = useCallback(
		(id: string): void => {
			navigate(routes.accounts.details(id, AccountsTab.BASIC_DATA));
		},
		[navigate],
	);

	const handleCreateAccount = useCallback(() => {
		navigate(routes.accounts.create);
	}, [navigate]);

	const canCreateAccountPermission = useMemo<boolean>(() => {
		if (role === 'client_super_admin') return true;
		const permission = permissions.find(permission => !permission.accountId);
		return (
			permission?.permissionsNames?.includes('client_can_create_accounts') ??
			false
		);
	}, [role, permissions]);

	const canViewAccountPermission = useCallback(
		(id: string): boolean => {
			if (role === 'client_super_admin') return true;
			const permission = permissions.find(
				permission => permission.accountId === id,
			);
			return (
				permission?.permissionsNames?.includes('client_can_view_account') ??
				false
			);
		},
		[role, permissions],
	);

	const canEditAccountPermission = useCallback(
		(id: string): boolean => {
			if (role === 'client_super_admin') return true;
			const permission = permissions.find(
				permission => permission.accountId === id,
			);
			return (
				permission?.permissionsNames?.includes('client_can_edit_account') ??
				false
			);
		},
		[role, permissions],
	);

	useEffect(() => {
		void AccountService.getAccountsData().then(data => {
			dispatch(setBillingData(data));
		});
	}, []);

	if (!myBillsData) return <></>;

	return (
		<div className="pb-10 flex flex-col h-full">
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-gray-900 text-2xl font-semibold">
					{t('modules.accounts.title')}
				</h1>
				{canCreateAccountPermission && (
					<Button
						disabled={!has2fa}
						data-test-id="client-account-create"
						variant="create"
						onClick={handleCreateAccount}>
						{t('modules.accounts.create')}
						{!has2fa && (
							<Tooltip
								title={t('ui.tooltip.2fa.title')}
								text={t('ui.tooltip.2fa.text')}
							/>
						)}
					</Button>
				)}
			</div>
			<Modal
				show={confirmationModalOpened}
				onClose={handleCloseConfirmationModal}>
				<div className="max-w-[344px]">
					<h3 className="text-center text-gray-900 font-medium mb-3">
						{t('ui.modal.areYouSure')}
					</h3>
				</div>
				<div className="flex justify-between items-center">
					<Button
						data-test-id="client-account-status-confirm"
						variant="create"
						onClick={() => {
							if (!confirmationModalItem) return;
							void handleConfirmStatus(confirmationModalItem.id);
						}}
						className="w-1/2 mr-3 justify-center">
						{t('ui.buttons.yes')}
					</Button>
					<Button
						data-test-id="client-account-status-close"
						variant="secondary"
						onClick={handleCloseConfirmationModal}
						className="w-1/2 justify-center">
						{t('ui.buttons.close')}
					</Button>
				</div>
			</Modal>

			{myBillsData.map(item => {
				return (
					<div
						key={item.id}
						className="mb-4 rounded-lg border border-gray-200 shadow-md">
						<div className="flex items-center justify-between px-6 py-4 border-b">
							<div className="flex items-center">
								<img
									src={`${item.logoUri}?cache=${Date.now()}`}
									onError={(event: SyntheticEvent) => {
										event.currentTarget.classList.add('hidden');
									}}
									className="w-10 h-10 rounded-full"
									alt="logo"
								/>
								<div className="ml-3 text-sm">
									<div className="font-medium">{item.title}</div>
									<div className="text-gray-500">ID {item.businessId}</div>
								</div>
							</div>
							<div className="flex items-center">
								<div className="flex mr-3 relative">
									<label className="mr-2">{t('modules.accounts.status')}</label>
									<Switch
										data-test-id="client-account-status-toggle"
										disabled={
											item.status === moderationStatus ||
											item.status === blockedStatus ||
											!has2fa ||
											!canEditAccountPermission(item.id)
										}
										checked={item.status === activeStatus}
										onChange={() => {
											handleOpenConfirmationModal(item);
										}}
									/>
									{!has2fa && (
										<Tooltip
											title={t('ui.tooltip.2fa.title')}
											text={t('ui.tooltip.2fa.text')}
										/>
									)}
								</div>
								{canViewAccountPermission(item.id) && (
									<Button
										data-test-id="client-account-details"
										variant="secondary"
										onClick={() => {
											goToAccountDetails(item.id);
										}}>
										{t('ui.buttons.details')}
									</Button>
								)}
							</div>
						</div>

						<div className="flex bg-white overflow-auto">
							{item.wallets.map(wallet => {
								return (
									<div
										key={wallet.title}
										className="flex items-center justify-between min-w-fit px-6 py-4">
										<div>
											<div className="flex items-center">
												<img
													src={wallet.logoUri}
													className="w-9 h-6 rounded-full object-contain"
													alt="logo"
												/>
												<div className="text-gray-700 text-sm ml-1 max-w-[250px] text-ellipsis  overflow-hidden">
													{wallet.title}
												</div>
											</div>
											<div className="text-sm mt-2">
												<div className="text-gray-700 font-medium text-sm">
													{wallet.balance} {wallet.currency}
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
			{myBillsData.length === 0 && (
				<div className="flex items-center justify-center h-full">
					{t('modules.accounts.empty')}
				</div>
			)}
		</div>
	);
};

export default Accounts;
