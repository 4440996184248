import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { type ITab } from 'shared/types';
import { SettingsTab } from '../types';
import { useAppSelector } from 'shared/hooks';

type ReturnType = {
	tabs: ITab[];
	activeTab: ITab;
	handleTabClick: (tab: ITab) => void;
};
export const useSettingsTabs = ({ tab }: { tab?: string }): ReturnType => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { userId } = useParams();

	const permissions = useAppSelector(state => state.app.user.permissions);

	const handleCreateEmployeeClick = useCallback((): void => {
		navigate(routes.settings.createEmployee);
	}, [navigate]);

	const canEditUsers = useMemo<boolean>(() => {
		const general = permissions.find(permission => !permission.accountId);
		if (!general) return false;
		return general.permissionsNames?.includes('client_can_edit_users');
	}, [permissions]);

	const tabs = useMemo<ITab[]>(
		() => [
			{
				id: 1,
				value: SettingsTab.BASIC_DATA,
				text: t('ui.tabs.basicData'),
			},
			{
				id: 2,
				value: SettingsTab.CHANGE_PASSWORD,
				text: t('ui.tabs.changePassword'),
			},
			...(canEditUsers
				? [
						{
							id: 3,
							value: SettingsTab.EMPLOYEES,
							text: t('ui.tabs.employees'),
							action: {
								disabled: Boolean(userId),
								name: t('modules.settings.employees.create'),
								onClick: handleCreateEmployeeClick,
							},
						},
				  ]
				: []),
		],
		[handleCreateEmployeeClick, t, canEditUsers, userId],
	);

	const activeTab = useMemo<ITab>(() => {
		return tabs.find(tb => tb.value === tab) ?? tabs[0];
	}, [tab, tabs]);

	const handleTabClick = useCallback(
		(tab: ITab) => {
			if (activeTab.id === tab.id) return;
			navigate(routes.settings.index(tab.value));
		},
		[activeTab.id, navigate],
	);

	return { tabs, activeTab, handleTabClick };
};
