import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IMerchantUser, type ISettingsData } from '../types';
import {
	type ITableSettings,
	type Nullable,
	Order,
	type UpdateTableData,
} from 'shared/types';
import { type ColDef } from 'ag-grid-community';
import { initSettings } from '../constants';

interface SettingsState {
	settings: ISettingsData;
	employees: {
		rows: IMerchantUser[];
		columns: Array<ColDef<IMerchantUser>>;
		settings: ITableSettings;
		totalPages: number;
		isLoading: boolean;
		selected: Nullable<IMerchantUser>;
	};
}

const initialState: SettingsState = {
	settings: initSettings,
	employees: {
		columns: [],
		rows: [],
		settings: {
			Keyword: '',
			OrderBy: '',
			OrderDir: Order.EMPTY,
			PageNumber: 1,
			PageSize: 25,
		},
		totalPages: 1,
		isLoading: false,
		selected: null,
	},
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setSettings(state, { payload }: PayloadAction<ISettingsData>) {
			state.settings = payload;
		},
		setSelectedMerchantUsersRows(
			state,
			{ payload }: PayloadAction<IMerchantUser[]>,
		) {
			state.employees.rows = payload;
		},
		setSelectedMerchantUsersCols(
			state,
			{ payload }: PayloadAction<Array<ColDef<IMerchantUser>>>,
		) {
			state.employees.columns = payload;
		},
		updateSelectedMerchantUsersSettings(
			state,
			{ payload }: PayloadAction<UpdateTableData>,
		) {
			state.employees.settings[payload.key] = payload.value as never;
		},
		setSelectedMerchantUsersTotalPages(
			state,
			{ payload }: PayloadAction<number>,
		) {
			state.employees.totalPages = payload;
		},
		setSelectedMerchantEmployee(
			state,
			{ payload }: PayloadAction<Nullable<IMerchantUser>>,
		) {
			state.employees.selected = payload;
		},
	},
});

export const {
	setSettings,
	setSelectedMerchantUsersRows,
	setSelectedMerchantUsersCols,
	updateSelectedMerchantUsersSettings,
	setSelectedMerchantUsersTotalPages,
	setSelectedMerchantEmployee,
} = settingsSlice.actions;

export default settingsSlice.reducer;
