const generateUrl = (url: string): string => {
	return `/v1/${url}`;
};

const api = {
	APP: {
		GET_ENUMS: generateUrl('enums'),
		GET_SERVICES: generateUrl('services'),
		GET_CURRENCIES: generateUrl('currencies'),
		GET_ROLES: generateUrl('roles'),
		GET_PERMISSIONS: generateUrl('permissions'),
	},
	ACCOUNTS: {
		GET_ALL: generateUrl('accounts'),
		GET_ONE: (id: string) => generateUrl(`accounts/${id}`),
		UPDATE_ONE: (id: string) => generateUrl(`accounts/${id}`),
		UPDATE_LOGO: (id: string) => generateUrl(`accounts/${id}/logo`),
		UPDATE_STATUS: (id: string) => generateUrl(`accounts/${id}/toggle`),
		GET_ACCOUNT_WALLETS: (id: string) => generateUrl(`accounts/${id}/wallets`),
		GET_ACCOUNT_INTEGRATION_SETTINGS: (id: string) =>
			generateUrl(`accounts/${id}/integrations/settings`),
		GENERATE_KEY: generateUrl('accounts/api-key/generate'),
		GET_ACCOUNT_AVAILABLE_INTEGRATIONS: (id: string) =>
			generateUrl(`accounts/${id}/integrations/available`),
		INTEGRATE_WALLET: (id: string) =>
			generateUrl(`accounts/${id}/integrations`),
	},
	OPERATIONS: {
		GET_ALL: generateUrl('operations'),
		GET_ONE: (id: string) => generateUrl(`operations/${id}`),
		GET_LOGS: (id: string) => generateUrl(`operations/${id}/logs`),
		GET_TRANSACTIONS: generateUrl('transactions'),
		GET_TRANSACTION_BY_ID: (id: string) => generateUrl(`transactions/${id}`),
		GET_TRANSACTION_LOGS: (id: string) =>
			generateUrl(`transactions/${id}/audit-logs`),
		GET_BLOCK_DATA: (id: string) =>
			generateUrl(`operations/${id}/blocked-external-user`),
		EXPORT: generateUrl('operations/report'),
	},
	SETTINGS: {
		GET_SETTINGS: generateUrl('profile'),
		UPDATE_SETTINGS: generateUrl('profile'),
		UPDATE_TWO_FACTOR: generateUrl('merchant/settings/2fa'),
		CONFIRM_TWO_FACTOR: generateUrl('merchant/settings/2fa/verify'),
		GET_RECOVERY_CODES: generateUrl('merchant/settings/2fa/recovery-codes'),
		UPDATE_PASSWORD: generateUrl('merchant/password'),
		TOGGLE_IP_CHECKED: generateUrl('merchant/settings/ip-check'),
		GET_EMPLOYEES: generateUrl('users'),
		CREATE_EMPLOYEE: generateUrl('users'),
		GET_EMPLOYEE: (id: string) => generateUrl(`users/${id}`),
		UPDATE_EMPLOYEE: (id: string) => generateUrl(`users/${id}`),
	},
	WITHDRAWALS: {
		GET_ALL: generateUrl('withdrawals'),
		GET_AVAILABLE: generateUrl('withdrawals/available'),
		CHECK_DESTINATION: (walletId: string) =>
			generateUrl(`withdrawals/${walletId}/destination`),
		CHECK_AMOUNT: (walletId: string) =>
			generateUrl(`withdrawals/${walletId}/amount`),
		POST_WITHDRAWAL: (walletId: string) =>
			generateUrl(`withdrawals/${walletId}`),
		GET_ONE: (id: string) => generateUrl(`withdrawals/${id}`),
		GET_TRANSACTIONS: (id: string) =>
			generateUrl(`withdrawals/${id}/transactions`),
		EXPORT: generateUrl('withdrawals/report'),
	},
};

export default api;
