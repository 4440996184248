import {
	discovery,
	randomPKCECodeVerifier,
	calculatePKCECodeChallenge,
	randomNonce,
	buildAuthorizationUrl,
	authorizationCodeGrant,
	fetchUserInfo,
	type Configuration,
	refreshTokenGrant,
	buildEndSessionUrl,
} from 'openid-client';
import { config } from 'shared/constants/config';

let sharedConfig: Configuration | null = null;

const getClientConfig = async (): Promise<Configuration> => {
	if (!sharedConfig) {
		const server = new URL(config.oidcAuthority);
		sharedConfig = await discovery(server, config.oidcClientId);
	}

	return sharedConfig;
};

const oidcClient = {
	randomPKCECodeVerifier,
	calculatePKCECodeChallenge,
	randomNonce,
	buildAuthorizationUrl,
	authorizationCodeGrant,
	fetchUserInfo,
	getClientConfig,
	refreshTokenGrant,
	buildEndSessionUrl,
};

export default oidcClient;
